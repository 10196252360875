import { Icon } from '@/components/common';
import styled from 'styled-components';

export const WondeActivationWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  position: relative;
  padding: 8px auto 8px 16px;
  border-radius: 8px;
  font-size: ${(props) => props.theme.font.md};
  line-height: 18px;
  background-color: ${(props) => props.theme.color.blue50};
  color: ${(props) => props.theme.color.blue800};
`;

export const IconWonde = styled(Icon)`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 16px;
`;
