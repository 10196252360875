import { Loader } from '@/components/common';
import Btn from '@/components/common/Btn/Btn';
import DakotaDrawerRegistration from '@/components/common/commonComponents/DakotaDrawerRegistration/DakotaDrawerRegistration';
import Icon from '@/components/common/Icon/Icon';
import { IconTypes } from '@/components/common/Icon/types';
import RegistrationLayout from '@/components/common/RegistrationLayout/RegistrationLayout';
import LearnerLoginForm from '@/components/learner/LearnerLoginForm/LearnerLoginForm';
import ParentLoginForm from '@/components/parent/ParentLoginForm/ParentLoginForm';
import TeacherLoginForm from '@/components/teacher/TeacherLoginForm/TeacherLoginForm';

import { getEnvVars } from '@/modules/common';
import {
  CallToLogin,
  LoaderWrapper,
  LoginFormWrapper,
  MainWrapper,
  Wrapper,
} from '@/styles/pagesStyles/login.styles';
import {
  RegistrationLayoutLeft,
  RegistrationLayoutRight,
  RegistrationLayoutWrapper,
  SeparationLine,
  SignInGoogleText,
  SignInMicrosoftText,
  SignInOptions,
} from '@/styles/pagesStyles/registration/registration.styles';
import { Collapse } from '@chakra-ui/react';
import { useAuth } from 'hooks/useAuth';
import useSafePush from 'hooks/useSafePush';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const { API_URL } = getEnvVars();

const Login = (): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearchParams();

  const { safePush } = useSafePush();
  const { isLoginLoading, loginWithCredentials } = useAuth();

  const [isRedirectLoading, setIsRedirectLoading] = useState(true);
  const [loginError, setLoginError] = useState('');

  const activeRouterTab =
    searchParams.get('tab') || localStorage.getItem('lastLoginTab') || 'Parent';

  useEffect(() => {
    localStorage.clear();
    router.push('/login');
  }, []);

  useEffect(() => {
    const lastLoginTab = localStorage.getItem('lastLoginTab');
    if (lastLoginTab) {
      router.replace(
        {
          pathname: '/login',
          query: {
            tab: lastLoginTab,
          },
        },
        undefined,
        { shallow: false }
      );
    }
    setTimeout(() => {
      setIsRedirectLoading(false);
    }, 300);
  }, []);

  const handleLogin = (username: string, password: string, role: string) => {
    loginWithCredentials(username, password, role, {
      onSuccess: () => {
        setIsRedirectLoading(true);
        safePush('/redirect');
        localStorage.setItem('lastLoginTab', activeRouterTab);
      },
      onError: (error) => {
        setIsRedirectLoading(false);
        setLoginError(error.message);
      },
    });
  };

  const MainTabs = [
    {
      title: 'Parent',
      icon: 'parents' as IconTypes,
      component: (
        <ParentLoginForm
          onLoginClick={handleLogin}
          error={loginError}
          isLoading={isLoginLoading}
        />
      ),
    },
    {
      title: 'Student',
      icon: 'hatStudent' as IconTypes,
      component: (
        <LearnerLoginForm
          onLoginClick={handleLogin}
          error={loginError}
          isLoading={isLoginLoading}
        />
      ),
    },
    {
      title: 'School',
      icon: 'schooleTeacher' as IconTypes,
      component: (
        <TeacherLoginForm
          onLoginClick={handleLogin}
          error={loginError}
          isLoading={isLoginLoading}
        />
      ),
    },
  ];

  return isRedirectLoading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <RegistrationLayoutWrapper>
      <RegistrationLayoutLeft>
        <RegistrationLayout
          onClick={() => router.push('https://studyhall.ai/')}
        >
          <CallToLogin>Login to Study Hall</CallToLogin>
          <Wrapper>
            <MainWrapper>
              <Collapse
                in={
                  activeRouterTab === 'Parent' ||
                  activeRouterTab === 'School' ||
                  activeRouterTab === 'Student'
                }
                transition={{
                  enter: { duration: 0.3 },
                  exit: { duration: 0.3 },
                }}
                animateOpacity
                startingHeight="0.01em"
              >
                <SignInOptions>
                  <Btn
                    variant="ssoPrimary"
                    leftIcon={
                      <Icon type="googleColored" size="smd" color="black" />
                    }
                    onClick={() => router.push(`${API_URL}/auth/google`)}
                  >
                    <SignInGoogleText>Log in with Google</SignInGoogleText>
                  </Btn>
                  {/* commented it just for the demo cause its not functional yet */}
                  <Btn
                    variant="ssoPrimary"
                    leftIcon={
                      <Icon type="microsoft" size="smd" color="black" />
                    }
                    onClick={() => router.push(`${API_URL}/auth/microsoft`)}
                  >
                    <SignInMicrosoftText>
                      Log in with Microsoft
                    </SignInMicrosoftText>
                  </Btn>
                </SignInOptions>
                <SeparationLine>or</SeparationLine>
              </Collapse>
              <LoginFormWrapper>
                {/* <ParentLoginForm
                  onLoginClick={handleLogin}
                  error={loginError}
                  isLoading={isLoginLoading}
                /> */}
                {/* <LearnerLoginForm
                  onLoginClick={handleLogin}
                  error={loginError}
                  isLoading={isLoginLoading}
                /> */}
                <TeacherLoginForm
                  onLoginClick={handleLogin}
                  error={loginError}
                  isLoading={isLoginLoading}
                />
              </LoginFormWrapper>
            </MainWrapper>
          </Wrapper>
        </RegistrationLayout>
      </RegistrationLayoutLeft>
      <RegistrationLayoutRight>
        <DakotaDrawerRegistration />
      </RegistrationLayoutRight>
    </RegistrationLayoutWrapper>
  );
};
export default Login;
