import { Checkbox, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormElement from '@/common/FormElement/FormElement';
import GroupWrapper from '@/common/GroupWrapper/GroupWrapper';
import InputPassword from '@/common/InputPassword/InputPassword';
import Btn from '@/components/common/Btn/Btn';
import { useAuthContext } from '@/contexts/AuthContext';
import { findPasswordState } from '@/modules/helpers';
import {
  CallForSignUP,
  ForgotPassword,
  NoAccountText,
  RememberAndForgotWrapper,
  SignUpText,
  ValidationErrorMessage,
} from '@/styles/pagesStyles/login.styles';
import { LoginForm } from '@/styles/pagesStyles/registration/registration.styles';
import { useAuth } from 'hooks/useAuth';
import useSafePush from 'hooks/useSafePush';
import { IconWonde, WondeActivationWrapper } from './TeacherLoginForm.styles';

type LoginPropsType = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const initialValues = {
  email: '',
  password: '',
  rememberMe: true,
};

const errorMessages: { [key: string]: string } = {
  isValidEmail: 'Your email is not valid',
};

const TeacherLoginForm: React.FC<{
  onLoginClick: (username: string, password: string, role: string) => void;
  error: string;
  isLoading: boolean;
}> = ({ onLoginClick, error, isLoading }): JSX.Element => {
  const [, setLoginError] = useState<string>('');
  const { logout } = useAuth();
  const { auth } = useAuthContext();
  const { safePush } = useSafePush();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<LoginPropsType>({
    mode: 'all',
    delayError: 0,
    defaultValues: initialValues,
  });

  const isEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // TODO: We need to rethink the role implementation for the login
  const onSubmit: SubmitHandler<LoginPropsType> = (data: LoginPropsType) => {
    const processedEmail = isEmail(data.email)
      ? data.email.trim().toLowerCase()
      : data.email.trim();

    onLoginClick(
      processedEmail,
      data.password.trim(),
      'customer,learner,student,teacher,school-admin'
    );
  };

  return (
    <LoginForm method="POST" onSubmit={handleSubmit(onSubmit)}>
      {(auth.role === 'school-admin' &&
        auth.decodedAccessToken?.isActivated === false) ||
        (error === 'Wonde Error' && (
          <WondeActivationWrapper>
            <IconWonde type="wonde" size="sm" />
            We are waiting for Wonde to approve your Account.
          </WondeActivationWrapper>
        ))}
      <FormElement
        label="Email or Username"
        error={
          errors.email?.message ||
          '' ||
          (errors.email?.type && errorMessages[errors?.email.type])
        }
        htmlFor="email"
        arePointerEventsDisabled={isLoading}
      >
        <GroupWrapper isInvalid={Boolean(errors.email)}>
          <Input
            isInvalid={Boolean(errors.email)}
            placeholder="Enter your email"
            id="email"
            {...register('email', {
              required: 'Email is required',
              onChange: () => setLoginError(''),
            })}
          />
        </GroupWrapper>
      </FormElement>

      <FormElement
        label="Password"
        error={errors.password?.message}
        htmlFor="password"
        arePointerEventsDisabled={isLoading}
      >
        <InputPassword
          id="password"
          disabled={false}
          passwordState={findPasswordState(
            'login',
            Boolean(getValues('password')),
            Boolean(errors.password)
          )}
          register={register('password', {
            required: 'Password is required',
            onChange: () => setLoginError(''),
          })}
        />
      </FormElement>

      <RememberAndForgotWrapper>
        <FormElement label="" htmlFor="rememberMe">
          <Checkbox id="rememberMe" {...register('rememberMe')}>
            Remember me
          </Checkbox>
        </FormElement>

        <ForgotPassword onClick={() => safePush('/forgot-password')}>
          Forgot password?
        </ForgotPassword>
      </RememberAndForgotWrapper>
      {error !== 'Wonde Error' && (
        <ValidationErrorMessage>{error}</ValidationErrorMessage>
      )}
      <Btn type="submit" size="lg" isLoading={isLoading} isDisabled={isLoading}>
        Log in
      </Btn>

      <CallForSignUP>
        <NoAccountText>Don&apos;t have an account?</NoAccountText>
        <SignUpText
          onClick={() => {
            logout(false);
            safePush('/signup');
          }}
        >
          Sign up
        </SignUpText>
      </CallForSignUP>
    </LoginForm>
  );
};

export default TeacherLoginForm;
