import { Checkbox, Input } from '@chakra-ui/react';
import router from 'next/router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import FormElement from '@/common/FormElement/FormElement';
import GroupWrapper from '@/common/GroupWrapper/GroupWrapper';
import InputPassword from '@/common/InputPassword/InputPassword';
import Btn from '@/components/common/Btn/Btn';
import { findPasswordState, isValidEmail } from '@/modules/helpers';
import {
  CallForSignUP,
  ForgotPassword,
  NoAccountText,
  RememberAndForgotWrapper,
  SignUpText,
  ValidationErrorMessage,
} from '@/styles/pagesStyles/login.styles';
import { LoginForm } from '@/styles/pagesStyles/registration/registration.styles';
import { useAuth } from 'hooks/useAuth';

type LoginPropsType = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const initialValues = {
  email: '',
  password: '',
  rememberMe: true,
};

const errorMessages: { [key: string]: string } = {
  isValidEmail: 'Your email is not valid',
};

const ParentLoginForm: React.FC<{
  onLoginClick: (username: string, password: string, role: string) => void;
  error: string;
  isLoading: boolean;
}> = ({ onLoginClick, error, isLoading }): JSX.Element => {
  const [loginError, setLoginError] = useState<string>('');
  const { logout } = useAuth();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<LoginPropsType>({
    mode: 'all',
    delayError: 0,
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<LoginPropsType> = (data: LoginPropsType) => {
    onLoginClick(
      data.email.toLowerCase().trim(),
      data.password.trim(),
      'customer'
    );
  };

  return (
    <LoginForm method="POST" onSubmit={handleSubmit(onSubmit)}>
      <FormElement
        label="Email"
        error={
          errors.email?.message ||
          '' ||
          (errors.email?.type && errorMessages[errors?.email.type])
        }
        htmlFor="email"
        arePointerEventsDisabled={isLoading}
      >
        <GroupWrapper isInvalid={Boolean(errors.email)}>
          <Input
            isInvalid={Boolean(errors.email)}
            placeholder="Enter your email"
            id="email"
            {...register('email', {
              required: 'Email is required',
              onChange: () => setLoginError(''),
              validate: {
                isValidEmail,
              },
            })}
          />
        </GroupWrapper>
      </FormElement>

      <FormElement
        label="Password"
        error={errors.password?.message}
        htmlFor="password"
        arePointerEventsDisabled={isLoading}
      >
        <InputPassword
          id="password"
          disabled={false}
          passwordState={findPasswordState(
            'login',
            Boolean(getValues('password')),
            Boolean(errors.password)
          )}
          register={register('password', {
            required: 'Password is required',
            onChange: () => setLoginError(''),
          })}
        />
      </FormElement>

      <RememberAndForgotWrapper>
        <FormElement label="" htmlFor="rememberMe">
          <Checkbox id="rememberMe" {...register('rememberMe')}>
            Remember me
          </Checkbox>
        </FormElement>

        <ForgotPassword
          onClick={() =>
            router.push({
              pathname: '/reset-password',
              query: {
                type: 'parent',
              },
            })
          }
        >
          Forgot password?
        </ForgotPassword>
      </RememberAndForgotWrapper>

      <ValidationErrorMessage>{error}</ValidationErrorMessage>

      <Btn type="submit" size="lg" isLoading={isLoading} isDisabled={isLoading}>
        Log in
      </Btn>

      <CallForSignUP>
        <NoAccountText>Don&apos;t have an account?</NoAccountText>
        <SignUpText
          onClick={() => {
            logout(false);
            router.push('/parent/registration/step1');
          }}
        >
          Sign up
        </SignUpText>
      </CallForSignUP>
    </LoginForm>
  );
};

export default ParentLoginForm;
